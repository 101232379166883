@import "../../../styles/_variables";

.Buyer_Crud_Page {
  padding: 25px;
  .img{
    margin-right: 15px;
    height: 2rem;
    cursor: pointer;
  }
  .Buyer_Crud_form {
    padding: 0px 48px;
    .logo_img_err {
      text-align: center;
    }
    .logo_container {
      text-align: center;
    }
    .selected_logo {
      justify-content: center;

      img {
        width: 120px;
        height: 120px;
      }
    }
    .logo_notSelected_img {
      color: $color-orange;
      font-size: 2.5rem;
      cursor: pointer;
    }
    .Buyer_crud_buttons {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      .Buyer_Cancel_btn {
        color: $color-orange;
        background-color: $color-text-invert;
        box-shadow: 0 0 5px $color-btn-shadow
      }
      .Buyer_Add_btn {
        background-color: $color-orange;
        color: $color-text-invert;
        margin-left: 20px;
      }
    }
  }
}
