@import "../../../../styles/variables";

.modal_footer {
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  background: $color-white;
}

.modal_header {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 15px;
  color: $color-black;
}