@import "../../../styles/_variables";

.form__input {
  width: auto;
  border: 2px solid $color-border;
  font-size: 1rem;
  padding: 0.625rem;
  flex: 1 1 auto;
  border-radius: 12px;

  &:hover {
    border: 2px solid $color-comman-hover-input;
  }
}

.search-icon {
  font-size: 25px;
  opacity: 0.3;
  flex-shrink: 0;
}

.searchbar__container {
  position: relative;
  width: inherit;
  display: flex;
}

.search__button {
  padding: 0px 10px;
  background: $color-orange;
  color: $color-text-invert;
  font-size: 17px;
  border: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  outline: none;
  cursor: pointer;
}

.mainDateRangePicker {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  right: 1%;
  top: 100%;
  box-shadow: 8px 8px 8px $color-date-box-shadow;
}

.button-container {
  width: 100%;
  border-top: 1px solid $color-button-border;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-text-invert;
  gap: 3rem;
  text-align: center;
}

.cancelButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-red;
  border-radius: 7px;
  text-transform: uppercase;
  color: $color-text-invert;
  background-color: $color-red;
  width: 32%;
  text-align: right;
}

.cancelButton:hover {
  background-color: $color-text-invert;
  color: $color-red;
  border: 2px solid $color-red;
  transition: all 0.5s;
}

.okButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-orange;
  border-radius: 7px;
  text-transform: uppercase;
  color: $color-text-invert;
  background-color: $color-orange;
  width: 32%;
  text-align: right;
}

.okButton:hover {
  background-color: $color-text-invert;
  color: $color-orange;
  border: 2px solid $color-orange;
  transition: all 0.5s;
}

.clearButton {
  border: 1px solid $color-gray;
  color: $color-text-invert;
  background-color: $color-gray;
}

.clearButton:hover {
  color: $color-red;
  border: 2px solid $color-red;
}