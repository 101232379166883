.option__wrapper__container {
  display: flex;
  align-items: center;
  white-space: nowrap !important;
}

.option_inner_wrapper {
  white-space: normal;
  display: flex;
}

.menu__label__text {
  font-size: 16px;
  font-weight: 300 !important;
  word-wrap: break-word;
}
