@import "../../../styles/_variables.scss";

.CompanyInfo_container {
  padding: 0px 200px;
  width: 100%;

  .profile_com_btn_div{
    border-top: 1px solid $color-border-top;
    padding-top: 20px;
    text-align: center;
  }

  @media (max-width: 1700px) {
    & {
      padding: 0px 150px;
    }
  }

  @media (max-width: 1600px) {
    & {
      padding: 0px 100px;
    }
  }

  @media (max-width: 1300px) {
    & {
      padding: 0px 50px;
    }
  }

  @media (max-width: 978px) {
    & {
      padding: 0px 10px;
    }
  }

  ._form {
    padding: 25px 75px;

    @media (max-width: 1024px) {
      & {
        padding: 25px 25px;
      }
    }

    @media (max-width: 768px) {
      & {
        padding: 0px 0px;
      }
    }

    .companyImg {
      text-align: center;
      cursor: pointer;
    }

    .selected_companyImg {
      justify-content: center;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .companyImg_notSelected_icon {
      color: $color-orange;
      font-size: 2.5rem;
      cursor: pointer;
    }

    .company_img_err {
      text-align: center;
    }

    .remove_address_btn {
      margin-left: 5px;
      margin-top: 1.9rem;

      background-color: $color-red;
      color: $color-text-invert;
      border-radius: 30px;
      height: 30px;
    }
  }
}