@import "../.././styles/variables";

.border_top {
    width: 100%;
    height: 7vh;
    background-color: $color-orange;
    display: flex;
    align-items: center;

    .border_img {
        width: 12rem;
        margin-left: 0.5rem;
    }
}

.main {
    height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .tile {
        margin-top: 0.5rem;
    }

    .login_btn {
        height: 35px;
        background-color: $color-orange;
        color: $color-text-invert;
        font-weight: bold;
        font-size: 18px;
        border-radius: 30px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        text-transform: capitalize;

        &:hover {
            background-color: $color-orange;
            color: $color-text-invert;
            transition: 0.3s ease-in-out;
        }
    }
}