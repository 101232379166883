@import "../.././styles/variables";

.orders_wrapper {
  .orders_header {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .order_header_info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .order_header_info_div {
      max-width: 450px;
      flex-wrap: wrap;
      gap: 10px;
      margin-right: 8px !important;
      margin-top: 2px;
    }

    .order_header_status_div {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      margin-left: 0.6rem;
    }

    .searchWrapper {
      margin-bottom: 3px;
      border-radius: 10px;
      overflow: hidden;
      padding-left: 0.18rem;
      border: 2px solid $color-border;
      width: 12rem;
      border-radius: 10px 0px 0px 10px !important;
      border-right: none;
    }

    .searchInput {
      height: 45px;
      font-size: 16px;
    }

    .searchIcon {
      font-size: 1.2rem;
      margin-top: 0.1rem;
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  .category_content {
    margin-top: 1rem;
  }

  .rs-table-cell-wrap {
    width: 100% !important;
  }

  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    background-color: $color-orange !important;
    color: $color-text-invert !important;
    font-weight: bold;
    font-size: 15px;
    height: 45px;
    width: 83px;
    border-radius: 10px;

    svg {
      top: 12px;
      right: 12px;
    }
  }

  .rs-dropdown-item {
    font-size: 16px;
  }
}

.search_icon {
  font-size: 1.2rem;
  margin-top: 0.1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.order_save_btn {
  width: 8px !important;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-orange !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  color: $color-orange !important;
  text-align: right;
  font-size: 12px !important;
}

.total_amount {
  height: 1.8rem;
  background-color: $color-div-background;
  display: flex;
  padding: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  border-radius: 7px;
  gap: 5px
}

.label-date-picker {
  font-size: 12px;
  font-weight: 500;
  color: $color-label;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0px;
  top: -20px;
  z-index: 1;
  background: $color-white;
}

.detail {
  display: flex;
}

.invoice_btn {
  display: flex;
  height: 2.1rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-orange;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  color: $color-text-invert !important;
  background-color: $color-orange !important;
  text-align: right;
  font-size: 16px !important;
}

.status_Search_btn {
  width: 38px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  cursor: pointer;
  padding: 0px 8px;
  background-color: $color-orange;
  color: $color-white;
  font-size: 13px;
  border: none,
  
}

.add_order_btn {
  display: flex;
  height: 2.8rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-orange;
  border-radius: 10px !important;
  color: $color-text-invert !important;
  background-color: $color-orange !important;
  width: 46px;
}

.status_label {
  z-index: 2;
  position: absolute;
  margin-top: -10px;
  margin-left: 3px;
  font-size: 12px;
  background: $color-white
}

@media (max-width: 1700px) {

  .orders_header {
    flex-direction: var(--flex-direction) !important;
    justify-content: none !important;

    .order_header_info {
      justify-content: end !important;
      row-gap: 10px;
    }
  }
}