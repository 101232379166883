@import "../../styles/_variables";

.main_dashboard {
    .main_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -5px;

        .main_title {
            font-weight: 600;
            font-size: 18px;
            color: $color-black;
        }

        .select_div {
            .status_label {
                z-index: 2;
                position: absolute;
                margin-top: -10px;
                margin-left: 3px;
                font-size: 12px;
                background: $color-white
            }
        }
    }

    .MuiPaper-elevation {
        padding: 0px !important;
        overflow-wrap: anywhere !important;
    }

    .item {
        box-shadow: $color-item-shadow 0px 2px 8px 0px;
        background-color: $color-item-background;
        display: flex;
        gap: 10px;

        .items_div {
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-Color: $color-orange;
            color: $color-white;
            border-radius: 8px 0 0 8px;
            padding: 5px;

            .item_icon {
                font-size: 25px;
                color: $color-white;
            }
        }

        .containe_div {
            display: flex;
            flex-direction: column;
            width: 80%;
            padding: 5px
        }

        .item_heading {
            font-weight: 600;
            font-size: 12px;
            color: $color-black;
        }

        .item_info_span {
            gap: 3px;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .tables_div {
        margin-top: 1.5rem;

        .pending_dashbord_checkbox {
            .checkbox_conatiner {
                display: flex !important;
                align-items: center !important;
            }

            .MuiTypography-root {
                font-size: 15px !important;
                font-weight: 600;
            }

            .MuiSvgIcon-root {
                width: 20px !important;
                height: 20px !important;
            }

            .MuiButtonBase-root {
                padding: 5px !important;
            }
        }

        .table_heading {
            display: flex;
            align-items: center;
            gap: 10px;

            .heading_title {
                font-weight: 600;
                font-size: 17px;
                color: $color-black;
            }

            .heading_count {
                font-size: 15px;
            }
        }
    }
}

@media (max-width: 1500px) {
    .item {
        .items_div {
            .item_icon {
                font-size: 20px !important;
            }
        }

        .item_info_span {
            font-size: 18px !important;
        }
    }

}