@import "../../../styles/_variables";

.header__helper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.data__text {
  color: $color-black;
  font-weight: 600;
  font-size: 18px;
}


@media (max-width: 1450px) {
  .data__text {
    font-size: 16px !important;
  }

  .data_count {
    font-size: 13px !important;
  }
}