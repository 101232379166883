@import "../../../../styles/variables";

.ordersDrawer_contain {
  width: 700px;

  .drawer_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    border-bottom: 1px solid $color-drawer-border;
    position: fixed;
    height: 80px;
    top: 0px;
    background-color: $color-text-invert;
    z-index: 10;
    width: 700px;
  }

  .drawer_footer {
    display: flex;
    justify-content: right;
    gap: 10px;
    align-items: center;
    padding: 0px 25px;
    border-top: 1px solid $color-drawer-border;
    position: fixed;
    height: 80px;
    bottom: 0px;
    background-color: $color-text-invert;
    z-index: 10;
    width: 700px;

    .Order_Cancel_btn {
      border: 1px solid $color-orange;
      color: $color-orange;
      background-color: $color-text-invert;
    }

    .Order_Add_btn {
      background-color: $color-orange;
      color: $color-text-invert;
    }
  }

  .order-address {
    display: flex;
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border-right: 0px !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .add-category-btn {
      height: 55px;
      background-color: $color-orange;
      color: $color-text-invert;
      border-radius: 30px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
}

.counter {
  border-radius: 32px;
  border: 1px solid $color-orange;
  margin-left: 1rem;
  height: 44px;
  margin-top: 1.4rem;
  padding: 0px 11px;
  width: 111px;
}

.plusBtn,
.minusBtn,
.count {
  display: inline-block;
  width: 35px;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
  color: $color-orange;
  height: 33px;
  font-weight: bold;
}

.Remove_Selected_Product_btn {
  margin-left: 10px;
  margin-top: 30px;
  border-radius: 20px;
  background-color: $color-text-invert;
}

.order_confirmation_data {
  font-size: 15px;
}

.error_label {
  color: $color-red ;
  font-weight: 600;
}