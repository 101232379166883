@import "../../../../styles/variables";

.prodtDrawer_contain {
  width: 600px;
  .drawer_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    border-bottom: 1px solid $color-drawer-border;
    position: fixed;
    height: 80px;
    top: 0px;
    background-color: $color-text-invert;
    z-index: 10;
    width: 600px;
  }
  .product_form {
    .productImg_container {
      text-align: center;
    }
    .selected_ProductImg {
      justify-content: center;
    }
    .productimg_notSelected_icon {
      color: $color-orange;
      font-size: 2.5rem;
      cursor: pointer;
    }
    .product_img_err {
      text-align: center;
    }
    .Unit_input_wrapper {
      margin-top: 20px;
    }
  }
  .drawer_footer {
    display: flex;
    justify-content: right;
    gap: 10px;
    align-items: center;
    padding: 0px 25px;
    border-top: 1px solid $color-drawer-border;
    position: fixed;
    height: 80px;
    bottom: 0px;
    background-color: $color-text-invert;
    z-index: 10;
    width: 600px;
    .Product_Cancel_btn {
      border: 1px solid $color-orange;
      color: $color-orange;
      background-color: $color-text-invert;
    }
    .Product_Add_btn {
      background-color: $color-orange;
      color: $color-text-invert;
    }
  }

  .add-category {
    display: flex;
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border-right: 0px !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .add-category-btn {
      height: 50px;
      background-color: $color-orange;
      color: $color-text-invert;
      border-radius: 30px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top:24px;
    }
  }
}
