@import "../../styles/variables";

.product_wrapper {
  .product_header {

    .product_header_form{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
    }

    .search_conatiner_product_div{
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
    }

    .Product_search_wrapper {
      margin-bottom: 3px;
      border-radius: 10px;
      overflow: hidden;
      padding-left: 0.18rem;
      border: 2px solid $color-border;
      width: 14rem;
      border-radius: 10px 0px 0px 10px !important;
      border-right: none;
    }

    .search_input_field {
      height: 45px;
      font-size: 16px;
    }

    .search_icon {
      font-size: 1.2rem;
      margin-top: 0.1rem;
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .deleteProduct_btn {
      display: flex;
      height: 2.8rem;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-red;
      border-radius: 10px !important;
      text-transform: capitalize !important;
      color: $color-text-invert !important;
      background-color: $color-red !important;
      text-align: right;
      font-size: 16px !important;
      margin-left: 1rem;
      // &:hover {
      //   color: $color-red !important;
      //   background-color: $color-text-invert !important;
      //   border: 2px solid $color-red;
      // }
    }
  }

  .product_content {
    margin-top: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
  }

  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    background-color: $color-orange !important;
    color: $color-text-invert !important;
    font-weight: bold;
    font-size: 16px;
    height: 45px;
    width: 100px;
    border-radius: 10px;

    svg {
      top: 12px;
      right: 12px;
    }
  }

  .rs-dropdown-item {
    font-size: 16px;
  }
}