@import "../.././styles/variables";

.page_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px;
  justify-items: center;
  align-items: center;
  height: 100%;

  .data_Form_radio {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
  }

  .data_Form_name {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
  }

  .data_Form_locality {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
  }

  .data_Form_city {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
  }

  @media (min-width: 768px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  //Left Side
  .page_left_side {
    background-color: $color-text-invert;
    height: 100%;
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
      & {
        max-width: 85%;
        margin: 0 auto;
        padding: 0px 64px;
      }
    }

    .page_left_side form {
      display: grid;
    }

    .leftside_header {
      padding: 0% 5%;

      @media (max-width: 1200px) {
        & {
          padding: 0%;
        }
      }

      .app_logo {
        margin-bottom: 1rem;
        text-align: center;

        @media (min-width: 768px) {
          & {
            display: none;
          }
        }
      }
    }

    .data_Form {
      padding: 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1200px) {
        & {
          padding: 0%;
        }
      }

      .Company_Add_btn {
        height: 45px;
        width: 100%;
        background-color: $color-orange;
        color: $color-text-invert;
        font-weight: bold;
        font-size: 18px;
        border-radius: 30px;
        margin-bottom: 1rem;
      }

      .logo_container {
        text-align: center;

        img {
          width: 120px;
          height: 120px;
        }
      }

      .logo_container {
        text-align: center;
      }

      .selected_logo {
        justify-content: center;
      }

      .logo_notSelected_img {
        color: $color-orange;
        font-size: 2.5rem;
        cursor: pointer;
      }

      .logo_img_err {
        text-align: center;
      }
    }

    .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
      margin-bottom: 0px !important;
    }
  }
}