$color-border: #dadada;
$color-text-invert: #fff;
$color-orange: #fc8019;
$color-red: #e4343c;
$color-black: #000;
$color-white: white;
$color-input-border: #e5e5ea;
$color-input-hover-border: #3498ff;
$color-input-focus-outline: rgba(52, 152, 255, 0.25);
$color-border-top : #e4e4e4;
$color-div-background: #e7fae3;
$color-label : rgba(0, 0, 0, 0.6);
$color-item-background: #fafafa;
$color-item-shadow: rgba(99, 99, 99, 0.2);
$color-gray : gray;
$color-background-head: #EAEAEB;
$color-drawer-border: #e4ebf4;
$color-background-nav: #F5F5F5;
$color-background-tooltip: #606060;
$color-warning : #FFB818;
$color-comman-hover-input: #8e8e8e;
$color-date-box-shadow : rgba(0, 0, 0, 0.1);
$color-button-border: #eff2f7;
$color-details-shadow : rgba(0, 0, 0, 0.2);
$color-btn-shadow : rgba(0, 0, 0, 0.5);