.reset_password_container {
  padding: 0px 300px;
  @media (max-width: 1600px) {
    & {
      padding: 0px 200px;
    }
  }
  @media (max-width: 1450px) {
    & {
      padding: 0px 150px;
    }
  }
  @media (max-width: 1300px) {
    & {
      padding: 0px 100px;
    }
  }
  @media (max-width: 1100px) {
    & {
      padding: 0px 70px;
    }
  }
  @media (max-width: 950px) {
    & {
      padding: 0px 25px;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 0px 0px;
    }
  }
  ._form {
    padding: 25px 75px;
    @media (max-width: 1024px) {
      & {
        padding: 25px 25px;
      }
    }
    @media (max-width: 768px) {
      & {
        padding: 0px 0px;
      }
    }
  }
}
